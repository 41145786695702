
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    background-color: $off-white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: $mobile-breakpoint) {
  html { font-size: 14px; }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#rotate-prompt {
  position: fixed;

  top: 50%;
  margin-top: -40%;

  left: 50%;
  margin-left: -40%;

  max-width: 70%;
  height: auto;
  background: rgba(207, 207, 207, 0.671);
  padding: 1rem;
  border-radius: 15px;

  z-index: 1;
  
  display: block;

  @media screen and (min-width: $vertical-breakpoint) {
    display: none;
  }
}

#content {
  z-index: 2;
}