
.transaction-card {
    font-size: $font-size-small;
    // margin: 0 0 10vh 0.5rem;
    margin: 0 0 0vh 0.5rem;

    min-height: 90vh;
    padding-bottom: 10vh;

    // opacity: 0.4;
    opacity: 1.0;

  @media screen and (max-width: $mobile-breakpoint) {
    min-height: 130vh;
    padding-bottom: 30vh;
  }
  
    /* max-width: 40%; */
    /* margin: auto; */
    /* height: 10vh; */
}

.transaction-card--featured {
  opacity: 1.0;
}

.transaction-card__visible-section {
  // border-left: 2px solid rgb(32, 32, 32);
  border-right: 2px solid rgb(32, 32, 32);
  background-color: rgba(184, 184, 184, 0.676);
  padding: 2rem 0.6rem;

  max-height: 70vh;
  overflow-y: scroll;
  // scrollbar-gutter: always;

  @media screen and (max-width: $mobile-breakpoint) {
    max-height: 100vh;
  }
}

.transaction-card__visible-section::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 5px;
  }
}
  
.transaction-card__visible-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.315);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  
.transaction-card__transaction-list {
  font-size: 0.8rem;
  list-style-type: none;
  padding-left: 0rem;
  margin-top: 1rem;

  // @media screen and (max-width: $mobile-breakpoint) {
  //   padding-left: 0rem;
  // }
}

.transaction-card__date-header {
  background-color: white;
  border-radius: 3px;
  font-weight: bold;
  margin: auto;
  padding: 0.5rem;
  text-align: center;
}

.transaction-card__transaction-item {
  margin-bottom: 0.4rem;
  background-color: white;
  padding: .5rem .4rem;
  border: 1px solid black;
  border-radius: 3px;
}