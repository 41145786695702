// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f9f9f9;

// Font Size
$font-size-large: 1.8rem;
$font-size-medium: 1.2rem;
$font-size-small: 0.9rem;
$font-size-xs: 0.8rem;

// Spacing
$s-size: 1.2rem;
$m-size: 2rem;
$l-size: 4rem;
$xl-size: 6rem;
$mobile-breakpoint: 1100px;
$small-mobile-breakpoint: 670px;
$vertical-breakpoint: 540px;