
.d3-tip {
    position: fixed;
    line-height: 1;
    padding: 0.6rem;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    border-radius: 3px;
    border: 1px solid black;
    pointer-events: none;
  }
  
  .d3-tip__grid {
    display: grid;
    // grid-template-columns: 2fr 1fr;
    grid-template-columns: max-content auto;
    grid-gap: 0.2rem 0.5rem;
  }
  
  .d3-tip__attr-list {
    list-style-type: none;
    padding-left: 0.5rem;
  }
  
  .d3-tip__player-name {
    font-size: $font-size-small;
    font-weight: bold;
    grid-column: 1 / 3;
    justify-self: center;
    padding-bottom: 0.3rem;
  }
  
  .d3-tip__player-attr {
    font-size: $font-size-xs;
    line-height: 1.1rem;
  }
  
  /* Creates a small triangle extender for the tooltip */
  .d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    pointer-events: none;

    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  
  /* Northward tooltips */
  .d3-tip.n:after {
    content: "\25BC";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
  }
  
  /* Eastward tooltips */
  .d3-tip.e:after {
    content: "\25C0";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
  }
  
  /* Southward tooltips */
  .d3-tip.s:after {
    content: "\25B2";
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
  }
  
  /* Westward tooltips */
  .d3-tip.w:after {
    content: "\25B6";
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
  }